<template>
    <div>
        <el-dialog
        :title="isFlag ? '编辑' : '新增' "
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="用户名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入用户名称"></el-input>
                </el-form-item>
                 <el-form-item label="用户登录名" prop="loginName">
                    <el-input v-model="ruleForm.loginName" placeholder="请输入用户名称"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                    <el-input type="password" v-model="ruleForm.pass" auto-complete="new-password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="newpassword">
                    <el-input type="password" v-model="ruleForm.newpassword" auto-complete="new-password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="roles">
                    <el-select v-model="ruleForm.roles" multiple placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="state" v-if="isFlag">
                  <el-radio-group v-model="ruleForm.state">
                    <el-radio :label="0" border>有效</el-radio>
                    <el-radio :label="1" border>锁定</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="创建时间" prop="createTime" v-if="isFlag">
                    <el-input v-model="ruleForm.createTime" disabled></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveUserInfoEvent">{{isFlag ? '修改' : '保存'}}</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { usermanagerAPI } from 'liankong-ui-api' 

  export default {
    data() {
      return {
        dialogVisible: false,
        ruleForm: {
          name: '',
          loginName: '',
          pass: '',
          newpassword: '',
          mobile: '',
          state: '',
          roles: [],
          createTime: ''
        },
        rules: {
          name: [
            { required: true, message: '用户名称不能为空', trigger: 'blur' },
          ],
          state: [
            { required: true, message: '请选择状态', trigger: 'change' }
          ]
        },
        options: [],
        isFlag: false, // 弹框标题
        newRowInfo: {},
      };
    },
    methods: {
      onLoad(isFlag, row){
        this.isFlag = isFlag;
        this.newRowInfo = row;
        this.$refs['ruleForm'].resetFields();
        if(isFlag){
          usermanagerAPI.getDetailUserInfo(row.id).then(res=>{
            if(res.code == 0) {
              let roleList = [];
               res.data.roleList.forEach(evItem=>{
                 roleList.push(evItem.id)
               })

              this.ruleForm = {
                name:res.data.name ,
                loginName: res.data.loginName,
                // pass: '',
                // newpassword: '',
                mobile: res.data.phoneNumber,
                state: res.data.locked,
                roles: roleList,
                createTime: this.transformTimestamp(res.data.createDate)
              }
            } else {
              this.$message.error(res.message)
            }
          }).catch(error=>{
            this.$message.error(error.message);
          })
        } else {
          this.ruleForm = {
            name: '',
            loginName: '',
            pass: '',
            newpassword: '',
            mobile: '',
            state: '',
            roles: [],
            createTime: ''
          }
        }

        this.getRolesListEvent()
      },
      // 获取角色列表
      getRolesListEvent(){
        let type = '1'
        usermanagerAPI.getRolesData(type).then(res=>{
            if(res.code == '0') {
              this.options = res.data;
              this.options.map(opts=>{
                opts.value = opts.id;
                opts.label = opts.name;
              })
            } else {
              this.$message.error(res.message)
            }
        }).catch(error=>{
          this.$message.error(error.message);
        })
      },
      //时间转换
      transformTimestamp(timestamp){
          let a = new Date(timestamp).getTime();
          const date = new Date(a);
          const Y = date.getFullYear() + '-';
          const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
          const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + '  ';
          const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
          const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':' ;
          const s = (date.getSeconds() <10 ? '0'+date.getSeconds() : date.getSeconds() ); // 秒
          const dateString = Y + M + D + h + m + s;

          return dateString;
      },
      // 添加、编辑
      saveUserInfoEvent(){
          this.$refs['ruleForm'].validate((valid) => {
              if (valid) {
                  if(this.isFlag) { // 修改
                    if(this.ruleForm.mobile.length > 0 &&(!/^1\d{10}$/.test(this.ruleForm.mobile))){
                       this.$message.error('请输入正确的手机号');
                    }

                    let editParams = {
                      "id": this.newRowInfo.id,
                      "locked": this.ruleForm.state, // 状态
                      "loginName": this.ruleForm.loginName, // 用户登录名
                      "loginPassword": this.ruleForm.pass, // 密码
                      "name": this.ruleForm.name, // 用户名称
                      "newpassword": this.ruleForm.newpassword , // 确认密码
                      "phoneNumber": this.ruleForm.mobile, // 手机号
                      "role": this.ruleForm.roles, // 角色
                    }
                    usermanagerAPI.editUser(editParams).then(res=>{
                        if(res.code == '0') {
                          this.$message({
                              message: '修改成功',
                              type: 'success'
                          });
                          this.dialogVisible = false;
                          this.$parent.formInline.pageNum = 1;
                           this.$emit('getPersonalUserList')
                        } else {
                            this.$message.error(res.message);
                            this.dialogVisible = false;
                        }
                    }).catch(error=>{
                      this.$message.error(error.message);
                    })

                  } else { // 添加
                    if(this.ruleForm.mobile.length > 0 &&(!/^1\d{10}$/.test(this.ruleForm.mobile))){
                       this.$message.error('请输入正确的手机号');
                    }

                    let addParams = {
                      // "locked": this.ruleForm.state, // 状态
                      "loginName": this.ruleForm.loginName, // 用户登录名
                      "loginPassword": this.ruleForm.pass, // 密码
                      "name": this.ruleForm.name, // 用户名称
                      "newpassword": this.ruleForm.newpassword , // 确认密码
                      "phoneNumber": this.ruleForm.mobile, // 手机号
                      "role": this.ruleForm.roles, // 角色
                    }
                    usermanagerAPI.addUser(addParams).then(res=>{
                       if(res.code == '0') {
                          this.$message({
                              message: '添加成功',
                              type: 'success'
                          });
                          this.dialogVisible = false;
                          this.$parent.formInline.pageNum = 1;
                          this.$emit('getPersonalUserList')
                        } else if(res.code == '1'){
                            this.$message({
                                message: res.message,
                                type: 'error'
                            });
                            this.dialogVisible = false;
                        }
                    }).catch(error=>{
                      this.$message.error(error.message);
                    })
                  }
              }
          })
      }
    }
  };
</script>
<style lang="less" scoped>
</style>
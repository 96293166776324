<template>
    <div class="organizational-page page-container">
        <div class="organizational-content">
            <div class="organizational-left">
                <h4>
                    <i class="el-icon-office-building"></i>
                    组织管理
                </h4>
                <div v-if='module == "storage"' class="storage-box-all">
                    <span>存储总空间：</span>
                    <el-progress  :stroke-width="10" :percentage="(sysInfosTotal/1853.2)*100" :format="format" ></el-progress>
                </div>
                <div class="organizational-btn" v-if=' module == "user" '>
                    <el-button type="text" icon="el-icon-circle-plus-outline" @click="handleBtnEvent('addOrg')">创建组织</el-button>
                    <el-button type="text" icon="el-icon-edit-outline" @click="handleBtnEvent('editOrg')" :disabled="currentStructureNodeData ? false :true">重命名</el-button>
                    <!-- <el-button type="text" icon="el-icon-delete" @click="handleBtnEvent('removeOrg')" :disabled="currentStructureNodeData ? false :true">删除组织</el-button> -->
                </div>
                <div class="mytree">
                    <el-tree
                        :data="orgStructureTree"
                        default-expand-all
                        node-key="id"
                        ref="tree"
                        highlight-current
                        :props="defaultProps"
                        :current-node-key="currentStructureNodeKey"
                        @current-change="handleOrgManagerChange">
                        <span class="text-box" slot-scope="{ node, data }">
                            <span class="org-icons" v-if="data.organizationId == undefined">企</span>
                            <!-- 不显示未分配部门 -->
                            <i class="el-icon-folder-opened" style="margin-right: 3px;" v-else-if="data.id"></i>
                            <span class="org-title" :title="data.name" >{{data.name}}</span>
                        </span>
                    </el-tree>
                </div>
                <div @click="selectedOrdinaryUsers" :class="isOrdinary?'ordinary-users active':'ordinary-users'">
                    <span ><i class="el-icon-upload2"></i></span>个人普通用户
                </div>
            </div>
            <div class="organizational-right">
                <div class="organizational-search">
                    <div v-if='isOrdinary && module == "user"'>
                       <el-button type="primary" @click="handleBtnEvent('add')" size='small'>新增用户</el-button>
                    </div>
                    <div v-if='module == "storage"'>
                        <h3 v-if='currentStructureNodeData'>{{currentStructureNodeData.name}}</h3>
                    </div>
                    
                    <div v-else>
                        
                    </div>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="用户名称">
                            <el-input v-model="formInline.name" placeholder="请输入用户名称" size="small"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search" size="small" @click="onSubmit" :disabled="tableData.length == 0">搜索</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="table-box">
                    <el-table :data="tableData" style="width:100%;" empty-text="暂无数据" v-loading='loading'>
                        <el-table-column
                        label="序号"
                        type="index"
                        align="center"
                        width="80px">
                        </el-table-column>
                        <el-table-column
                        label="用户名称"
                        align="center">
                         <template slot-scope="scope">
                                <span>{{scope.row.username || scope.row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="手机号码"
                        align="center">
                         <template slot-scope="scope">
                                <span>{{scope.row.mobile || scope.row.phoneNumber}}</span>
                            </template>
                        </el-table-column>
                         <el-table-column
                        v-if='module == "storage"'
                        label="存储空间"
                        align="center">
                         <template slot-scope="scope">
                                <span>{{scope.row.storageSpace | byteSize}}</span>
                            </template>
                        </el-table-column>
                          <el-table-column
                        label="创建日期"
                        align="center">
                         <template slot-scope="scope">
                                <span>{{scope.row.joinDate || scope.row.createDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        v-if='formInline.id'
                        label="组织身份"
                        align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.userId == currentStructureNodeData.owner ? '租户管理员' : '成员'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        v-if='formInline.id'
                        label="所属部门"
                        align="center">
                            <template slot-scope="scope">
                                <span v-if="currentStructureNodeData.organizationId">{{ currentStructureNodeData.name }}</span>
                                <span v-else>{{ scope.row.groupName ? scope.row.groupName : '--' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                        v-if='!formInline.id || module == "storage"'
                        label="操作"
                        align="center">
                            <template slot-scope="scope">
                                <el-button v-if='module == "user"' type="text" @click="handleBtnEvent('edit',scope.row)">编辑</el-button>
                               <el-button v-if='module == "user"' type="text" @click="handleBtnEvent('deleteUser',scope.row)">删除</el-button>
                               <el-button v-if='module == "storage"' type="text" @click="handleBtnEvent('expansion',scope.row)">扩容</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :current-page="formInline.pageNum"
                        :page-size="formInline.pageSize"
                        :total="total">
                    </el-pagination>
                </div>
               
            </div>
        </div>

        <!-- 添加组织 -->
        <addOrg ref='addOrg' @getList='getList'></addOrg>

        <!-- 重命名 -->
        <renameVue ref="renameVue" @getList='getList'></renameVue>
        <!-- 扩容 -->
        <addExpansion ref='addExpansion' @onSubmit='onSubmit'></addExpansion>

        <!-- 编辑 -->
        <user-item ref='userDialog'  @getPersonalUserList='getPersonalUserList'></user-item>
    </div>
</template>
<script>
import { orgAPI , monitormanagerAPI ,manageAPI } from 'liankong-ui-api'
import addOrg from './addOrg.vue'
import { Store } from '@/utils/LocalCache.js'
import renameVue from './rename.vue'
import addExpansion from './addExpansion.vue'
import UserItem from './UserItem.vue'
export default {
    props:{
        module:{
            type:String,
            default:''
        }
    },
    components:{
        addOrg,
        renameVue,
        addExpansion,
        UserItem
    },
    data() {
        return {
            orgStructureTree: [], // 组织列表数据
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            currentStructureNodeKey: '', // 组织tree选中标识
            currentStructureNodeData: null, // 组织tree选中信息
            formInline: {
                id: "",
                name: "",
                pageNum: 1,
                pageSize: 10
            },
            tableData: [], // 成员列表数据
            total: 0,
            loading:false,
            isOrdinary:false,
            sysInfosTotal:0,
            access_token:null
        }
    },
    mounted() {
        this.access_token = Store.get('access_token')
        this.getList(true);
        this.getSysInfosLists()
    },
    methods: {
        // 获取总存储空间
        getSysInfosLists(){
            monitormanagerAPI.getSysInfosList().then(res=>{
                if(res.code == '0') {
                    this.sysInfosTotal = Number(res.data.totalSize);

                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        format() {
            return `${this.sysInfosTotal} GB/ 1843.2 GB`;
        },
        // 选中普通用户
        selectedOrdinaryUsers(){
            this.isOrdinary = true;
            this.currentStructureNodeKey = null;
            this.currentStructureNodeData = null;
            this.tableData = [];
            this.$nextTick(()=>{
                if(this.$refs.tree) {
                    this.$refs.tree.setCurrentKey();
                } 
            })
            this.formInline = {
                id: "",
                name: "",
                pageNum: 1,
                pageSize: 10
            }
            this.getPersonalUserList()

        },
        // 获取普通用户
        getPersonalUserList(){
            orgAPI.getPersonalUserList(this.formInline).then(res => {
                if(res.code == 0){
                    this.tableData = res.data;
                    this.total = res.total;
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        // 获取部门成员
        getDepPerson() {
            this.formInline.id = this.currentStructureNodeData.id;
            manageAPI.toolDepMemberList(this.formInline, this.access_token).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 获取组织列表
        getList(flag) {
            orgAPI.getAllOrgList().then(res=>{
                if(res.code == 0) {
                    this.orgStructureTree = res.data;
                    
                    // 默认选中根组织
                    if(this.orgStructureTree.length != 0) {
                        if(flag) {
                            this.handleOrgManagerChange(this.orgStructureTree[0])
                        } else {
                            const newCurrentStructureNodeData = this.currentStructureNodeData ? this.orgStructureTree[0].children.filter(item=>item.id == this.currentStructureNodeData.id) : []
                            this.handleOrgManagerChange(newCurrentStructureNodeData.length>0 ? newCurrentStructureNodeData[0] : this.orgStructureTree[0])
                        }
                    }else{
                        this.selectedOrdinaryUsers()
                    }
                } else {
                    this.$message.error(res.message);
                }
            })
        },
    
        // 当前选中节点变化时触发的事件
        handleOrgManagerChange(node, data) {
            this.formInline.pageNum = 1;
            this.isOrdinary = false;
            this.currentStructureNodeKey = node.id;
            this.currentStructureNodeData = node;
            this.$nextTick(()=>{
                if(this.$refs.tree) {
                    this.$refs.tree.setCurrentKey(this.currentStructureNodeKey);
                } 
            })
            if(node.organizationId) { // 选中的是部门
                this.getDepPerson()
            } else { // 选中的是组织
                this.getOrgMemberList()
            }

            // this.getOrgMemberList()
            // 清空搜索框的值
            this.formInline.name = '';
        },
        // 获取成员
        getOrgMemberList() {
            this.loading = true;
            this.tableData = [];
            this.total = 0;
            this.formInline.id = this.currentStructureNodeData.id;
            orgAPI.getOrgMemberList(this.formInline).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.$message.error(res.message);
                }
            })
        },
        // 搜索
        onSubmit() {
            this.formInline.pageNum = 1;
            
            if(this.formInline.id){
                if(this.currentStructureNodeData.organizationId) {
                    this.getDepPerson()
                } else {
                    this.getOrgMemberList()
                }
            }else{
                this.getPersonalUserList()
            }
        },
        // 分页
        handleCurrentChange(num) {
            this.formInline.pageNum = num;
            if(this.formInline.id){
                if(this.currentStructureNodeData.organizationId) {
                    this.getDepPerson()
                } else {
                    this.getOrgMemberList()
                }
            }else{
                this.getPersonalUserList()
            }
        },
        // 添加用户回调
        reloadData() {
            this.handleOrgManagerChange(this.orgStructureTree[0])
        },
        // 按钮操作
        handleBtnEvent(type, row) {
            switch(type) {
                case 'addOrg': 
                    this.$refs.addOrg.dialogVisible = true
                    this.$nextTick(()=>{
                        this.$refs.addOrg.show();
                    })
                break;
                case 'add':
                    this.$refs.userDialog.dialogVisible = true;
                    this.$nextTick(()=>{
                        this.$refs.userDialog.onLoad(false, row);
                    })
                    break;
                case 'edit':
                    this.$refs.userDialog.dialogVisible = true;
                    this.$nextTick(()=>{
                        this.$refs.userDialog.onLoad(true, row);
                    })
                    break;

                case 'editOrg':
                    this.$refs.renameVue.dialogVisible = true;
                    this.$nextTick(()=>{
                        this.$refs.renameVue.show(this.currentStructureNodeData);
                    })
                break;
                case 'deleteUser':
                     this.$confirm('是否确认删除用户名称为"' + row.name + '"的数据项?', "警告", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        orgAPI.deleteUser(row.id).then(res=>{
                            if(res.code == 0 ) {
                                this.$message.success('删除成功');
                                this.getPersonalUserList();
                            } else {
                                this.$message.error(res.message);
                            }
                        }).catch(error=>{
                            this.$message.error(error.message);
                        })
                    })
                    break;
                case 'expansion':
                    this.$refs.addExpansion.dialogVisible = true;
                    this.$nextTick(()=>{
                        this.$refs.addExpansion.show(row);
                    })
                    break;

            }
        },
  

    }
}
</script>
<style lang="less" scoped>
.organizational-page {
    padding: 20px;
    .storage-box-all{
        padding: 10px;
        margin: 10px;
        border-bottom: 1px solid #efefef;
        display: flex;
        font-size: 14px;
        /deep/ .el-progress{
            width: 170px;
            .el-progress__text{
                font-size: 12px!important;
                color: #999;
                width: 100%;
                margin-top: 10px;
            }
            .el-progress-bar {
                padding-right: 0;
                width: 100%;
                margin-right: 0;
                box-sizing: border-box;
            }
        }
    }
    .ordinary-users{
        margin: 0 20px;
       cursor: pointer;
        font-size: 14px;
        color: #606266;
       span{
        display: inline-block;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        background: #409EFF;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        padding-top: 4px;
        margin-right: 6px;
        font-size: 16px;
       }
    }
    .ordinary-users.active{
        background: #f0f7ff;
    }

    .organizational-content {
        display: flex;
        .organizational-left {
            width: 300px;
            min-height: 600px;
            border: 1px solid #eee;
            h4 {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px 0;
                text-align: center;
                background: #669;
                color: #fff;
                margin: 0;
                // background: linear-gradient(157deg, rgba(167,102,160,0.12), rgba(2,111,184,0.12));
                i {
                    font-size: 23px;
                    margin-right: 5px;
                }
            }
            .organizational-btn {
                display: flex;
                justify-content: space-between;
                padding: 5px 10px;
            }
            /deep/ .mytree {
                .el-tree > .el-tree-node:after {
                    border-top: none;
                }
                .org-icons {
                    background: #7B79FF;
                    color: #fff;
                    border-radius: 14px;
                    text-align: center;
                    width: 26px;
                    font-size: 14px;
                    height: 26px;
                    line-height: 27px;
                    display: inline-block;
                    margin-right: 5px;
                }
                .el-tree-node {
                    position: relative;
                    padding-left: 21px;
                    margin: 10px 0;
                }
                //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
                .el-tree-node__expand-icon.is-leaf{
                    display: none;
                }
                .el-tree-node__children {
                    padding-left: 12px;
                    .el-tree-node__content {
                        display: flex!important;
                        padding-left: 10px !important;
                    }
                }
                .el-tree-node :last-child:before {
                    height: 38px;
                }
                .el-tree > .el-tree-node:before {
                    border-left: none;
                }
                .el-tree > .el-tree-node:after {
                    border-top: none;
                }
                .el-tree-node:before {
                    content: "";
                    left: -4px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                .el-tree-node:after {
                    content: "";
                    left: -4px;
                    position: absolute;
                    right: auto;
                    border-width: 1px;
                }
                .el-tree-node:before {
                    border-left: 1px dashed #4386c6;
                    bottom: 0px;
                    height: 100%;
                    top: -26px;
                    width: 1px;
                }
                .el-tree-node:after {
                    border-top: 1px dashed #4386c6;
                    height: 20px;
                    top: 12px;
                    width: 24px;
                }
                // .el-tree-node:last-child {
                //     display: none;
                // }
                .el-tree {
                    padding: 0 12px 0 0;
                    background: none;
                    .el-tree-node {
                        .text-box {
                            display: flex;
                            align-items: center;
                        }
                        .org-title {
                            display: block;
                            font-size: 14px;
                            width: 220px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .el-icon-folder-remove {
                            font-size: 20px;
                            color: #409EFF;
                        }
                        .expanded.el-tree-node__expand-icon.el-icon-caret-right, .el-tree-node__expand-icon.el-icon-caret-right{
                            font-size: 20px;
                            color: #303133;
                            margin-left: -30px;
                        }
                    }
                }
            }
        }
        .organizational-right {
            width: calc(100% - 300px);
            margin-left: 30px;
            .organizational-search {
                display: flex;
                justify-content:space-between;
                align-items: center;
                >div {
                    display: flex;
                    align-items: flex-start;
                }
            }
            .table-box {
                min-height: 600px;
                border: 1px solid #eee;
            }
            /deep/ .el-table {
                .el-table__header-wrapper {
                    .el-table__header {
                        width: 100% !important;
                    }
                    tr {
                        th {
                            background: #f2f2f2;
                            font-size: 14px;
                            font-weight: 400;
                            color: #602666;
                        }
                    }
                }
                .el-table__body-wrapper .el-table__body{
                    tr {
                        td {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
                .el-table__cell {
                    padding: 12px 0;
                }
                .el-table__row:hover {
                    td {
                        background: #f2f2f2 !important;
                    }
                }
            }
            /deep/ .el-pagination {
                margin-top: 20px;
                margin-right: 20px;
                text-align: right;
                padding: 0px;
                // .el-pager {
                //     .number.active {
                //         background: linear-gradient(72deg, #A766A0, #026FB8);
                //     }
                // }
                .btn-next {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
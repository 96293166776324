<template>
    <div>
        <el-dialog
        title="内存扩容"
        :close-on-click-modal="false" 
		width="600px"
		:before-close="cancleEvent"
        :visible.sync="dialogVisible">
            <div style="margin-bottom:15px;">
                用户当前使用内存：{{ dist | byteSize }}
            </div>
            <div style="margin-bottom:15px;">
                用户内存：{{ infos.storageSpace | byteSize }}
            </div>
            内存大小：<el-input-number v-model="num" :min="1" :max="1000" label="M"></el-input-number> MB
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleEvent">取 消</el-button>
                <el-button size="small" type="primary" @click="onSureEvent">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { documentAPI } from 'liankong-ui-api'
export default {
    data() {
      return {
        dialogVisible: false,
        num:1,
        infos: {},
        dist:0
      };
    },
    methods: {
        show(rows){
            this.infos = rows;
            this.getUserDist(rows.userId)
        },
        getUserDist(id){
            documentAPI.getUserDist(id).then(res => {
                if(res.code == 0){
                    this.dist = res.data;
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        onSureEvent(){
            documentAPI.addUserDist({
                userId:this.infos.userId,
                space:(this.num) * 1048576 + this.infos.storageSpace
            }).then(res => {
                if(res.code == 0){
                    this.$message.success('操作成功！')
                    this.$emit('onSubmit')
                    this.cancleEvent()
                }else{
                    this.$message.error(res.message)
                }
            })
        },
        cancleEvent(){
			this.infos = {}
            this.dialogVisible = false;
            this.num = 1;
            this.dist = 0;
        },
    }
};
</script>
<style lang="less" scoped>

</style>
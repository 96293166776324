<template>
    <div>
        <el-dialog
        title="修改组织名称"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="40%">
        <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="组织名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入组织名称" size="small"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" size="small" @click="save">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
import { orgAPI } from 'liankong-ui-api'
export default {
    props: ['access_token'],
    data() {
        return {
            dialogVisible: false,
            form: {
                name: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入组织名称', trigger: 'blur' },
                ]
            },
            rowsInfo:null
        }
    },
    methods: {
        show(row) {
            console.log(row)
            this.rowsInfo = row;
            this.form.name = row.name; 
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    orgAPI.renameOrganization({
                        id:this.rowsInfo.id,
                        name:this.form.name,
                        code:this.rowsInfo.code
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success('修改成功');
                            // 刷新列表
                            this.$emit('getList');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.rowsInfo = null;
                        this.form.name = ''; 
                        this.dialogVisible = false;
                    }).catch(error=>{
                        this.$message.error(error.message);
                        this.dialogVisible = false;
                    })
                    
                   
                }
            });
        }
    }
}
</script>